import * as React from "react";
import Layout from "../../components/layout";
import MissionContent from "../../components/about/missionContent";
import heroImage from "../../assets/about/vision-mssion-values-hero.jpg";
import BannerImage from "../../components/master/BannerImage";

const MissionPage = () => {
  return (
    <Layout>
      <BannerImage imagePath={heroImage} altText="Our Vision, Mission" />
      <MissionContent />
    </Layout>
  );
};

export default MissionPage;
