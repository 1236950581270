import React from "react";
import { about } from "../const";
import ContentWrapper from "../master/ContentWrapper";
import PageTitle from "../master/PageTittle";
import SideBar from "../master/SideBar";
import STRATEGICPLAN20212023 from "../../assets/about/Strategic Vision-final 27.07.2021.pdf";

const MissionContent = () => (
  <div className="container">
    <ContentWrapper sidebar={true} narrow={true}>
      <div className={"content"}>
        <PageTitle title="Mission" variant="h2" />
        <p>
          Inspired by the vision of the Maronite Sisters of the Holy Family,
          Saint Maroun’s College provides high quality learning that serves the
          needs of the broader community. We develop and nurture students into
          their fullest spiritual, academic, physical and social potential.
        </p>

        <PageTitle title="Vision" variant="h2" />
        <p>
          Our vision is to see our students becoming fully human in the likeness
          of Christ, to be discerning leaders and advocates leading positive
          transformation in our world.
        </p>

        <PageTitle title="College Values" variant="h2" />

        <p>
          In our learning the College seeks to foster in each child the values
          taught by Christ.
        </p>

        <p>
          <strong>Integrity:</strong> To act sincerely and honestly, having
          strong moral and ethical principles. Being true to who you truly are.
        </p>

        <p>
          <strong>Responsibility:</strong> The ability to respond, to willingly
          undertake the sacrifices necessary to create a productive and
          meaningful life that is pleasing to God.
        </p>

        <p>
          <strong>Compassion:</strong> Allows us to care for others, it refuses
          to be selfish and is willing to forgive. It freely embraces the rich
          diversity of humanity by treating everyone as equals and gives the
          desire to take action to improve human lives.
        </p>
        <PageTitle title="Strategic Plan" variant="h2" />
        
        <p>
The strategic plan determines the organisational priorities and goals for the future (2021-2023) and guides the desired outcomes for every aspect of the College.  At the core of the strategic plan is the College’s desire to develop young people to become fully human in the likeness of Christ, leading and advocating positive transformation in our world.
      
      
</p>
<a href={STRATEGICPLAN20212023} target="_blank">STRATEGIC PLAN 2021-2023</a>
      </div>
      <SideBar items={about} title="About Us" />
    </ContentWrapper>
  </div>
);

export default MissionContent;
